import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, TextField, Grid, Typography, Stack, Alert, TextareaAutosize } from '@mui/material';
import React, { useState, useEffect } from 'react';

// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { canUserApplyService } from 'helpers/users';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DownloadButton } from 'ui-component/CustomButton';
import { Formik, Field, useField, useFormikContext } from 'formik';
import { axiosPlainApi, axiosApiInstance } from 'helpers/api';

// assets
import { IconMenu2 } from '@tabler/icons';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const ApplyServiceButton = styled(Button)(() => ({
    textTransform: 'none',
    backgroundColor: '#A6CFA3',
    color: '#000',
    '&:hover': {
        backgroundColor: '#59A831',
        color: '#fff'
    }
}));

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();
    const userData = useSelector((state) => state.auth.userdata);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const [isSubmitedFormData, setIsSubmitedFormData] = useState(false);

    const submitFormData = async (params) => {
        const formDataUpdate = await axiosApiInstance.post('/api/selfservice/message/', {
            name: params.name,
            message: params.message
        });
        setIsSubmitedFormData(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 598,
                    display: 'flex',
                    justifyContent: 'left',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box sx={{ flexGrow: 0 }}>
                    <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.mediumAvatar,
                                transition: 'all .2s ease-in-out',
                                background: theme.palette.secondary.light,
                                color: theme.palette.secondary.dark,
                                '&:hover': {
                                    background: theme.palette.secondary.dark,
                                    color: theme.palette.secondary.light
                                }
                            }}
                            onClick={handleLeftDrawerToggle}
                            color="inherit"
                        >
                            <IconMenu2 stroke={1.5} size="1.3rem" />
                        </Avatar>
                    </ButtonBase>
                </Box>
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, ml: 2 }}>
                    {canUserApplyService(userData) && (
                        <Link to="/pieteikt-pakalpojumu" style={{ textDecoration: 'none', textTransform: 'none' }}>
                            <ApplyServiceButton variant="contained">Jauns pakalpojums</ApplyServiceButton>
                        </Link>
                    )}
                    {/**
                    <Button
                        variant="contained"
                        onClick={() => {
                            setOpen(true);
                            setIsSubmitedFormData(false);
                        }}
                        sx={{ textTransform: 'none' }}
                    >
                        Ziņot par portāla darbības problēmām
                    </Button>
                    <Dialog
                        maxWidth="xs"
                        fullWidth
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        PaperProps={{
                            style: {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }
                        }}
                    >
                        <Formik
                            initialValues={{
                                name: '',
                                message: ''
                            }}
                            onSubmit={async (values, { setErrors, setStatus, setSubmitting, setFieldValue }) => {
                                try {
                                    //submitClientFormStep();
                                    submitFormData(values);
                                    //setStatus({ success: true });
                                    //setSubmitting(false);
                                } catch (err) {
                                    console.error(err);
                                    setStatus({ success: false });
                                    setErrors({ submit: err.message });
                                }
                            }}
                        >
                            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                                <form noValidate onSubmit={handleSubmit}>
                                    <DialogContent dividers>
                                        <Box sx={{ margin: '20px' }}>
                                            {isSubmitedFormData && (
                                                <Stack sx={{ width: '100%', marginBottom: 2 }} spacing={2}>
                                                    <Alert severity="success">Ziņojums nosūtīts</Alert>
                                                </Stack>
                                            )}
                                            {!isSubmitedFormData && (
                                                <Grid container spacing={3} sx={{ mb: 3 }}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h5" gutterBottom>
                                                            Ziņot par problēmu
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="name"
                                                            name="name"
                                                            label="Vārds"
                                                            value={values.name}
                                                            onChange={handleChange}
                                                            error={touched.name && Boolean(errors.name)}
                                                            helperText={touched.name && errors.name}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            multiline
                                                            rows={2}
                                                            fullWidth
                                                            id="message"
                                                            name="message"
                                                            label="Ziņojums"
                                                            value={values.message}
                                                            onChange={handleChange}
                                                            error={touched.message && Boolean(errors.message)}
                                                            helperText={touched.message && errors.message}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Box>
                                    </DialogContent>
                                    <DialogActions>
                                        <Box
                                            sx={{
                                                width: '100%',
                                                margin: '20px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            {!isSubmitedFormData && (
                                                <DownloadButton type="submit" sx={{ mr: 1 }} variant="contained" onClick={handleSubmit}>
                                                    Nosūtīt
                                                </DownloadButton>
                                            )}
                                            <Button variant="contained" onClick={() => handleClose()}>
                                                Aizvērt
                                            </Button>
                                        </Box>
                                    </DialogActions>
                                </form>
                            )}
                        </Formik>
                    </Dialog>
                     */}
                </Box>
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, ml: 2 }}>
                    <Link to="/addresses" style={{ textDecoration: 'none', textTransform: 'none' }}>
                        <Button variant="contained" sx={{ textTransform: 'none' }}>
                            Izmaiņas esošā pakalpojumā
                        </Button>
                    </Link>
                </Box>
            </Box>

            {/* header search */}
            <Box sx={{ flexGrow: 1, textAlign: 'right' }}>
                <LogoSection />
            </Box>
            <Box sx={{ flexGrow: 1 }} />

            {/* notification & profile */}
            <ProfileSection />
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
